import request from '@/utils/request'

// 订单规则页面(新增初始化)
export function addInit() {
   return request({
     url: "/api/order/fbm/rule/addInit",
     method: "get"
   })
}

// 订单规则新增
export function handleAddRule(data) {
  return request({
    url: "/api/order/fbm/rule",
    data,
    method: "post"
  })
}

// 查询分页  
export function listPage(params) {
  return request({
    url: "/api/order/fbm/rule/listPage",
    params,
    method: "get"
  })
}

// 查询初始化
export function pageInit() {
  return request({
    url: "/api/order/fbm/rule/pageInit",
    method: "get"
  })
}

// 批量删除操作
export function batchDelete(data) {
  return request({
    url: "/api/order/fbm/rule/delete",
    data,
    method: "put"
  })
}

// 批量禁用操作
export function updateStatus(data) {
  return request({
    url: "/api/order/fbm/rule/status",
    data,
    method: "put"
  })
}

// 编辑初始化
export function updateInit(id) {
  return request({
    url: `/api/order/fbm/rule/updateInit/` + id,
    method: "get"
  })
}

// 确认编辑事件
export function updateRule(data) {
  return request({
    url: "/api/order/fbm/rule",
    data,
    method: "put"
  })
}

// 列表排序
export function handleSort(data) {
  return request({
    url: "/api/order/fbm/rule/sort",
    data,
    method: "post"
  })
}