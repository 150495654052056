<template>
  <!-- 订单规则 -->
  <div
   class="inboundManual"
   v-loading.fullscreen.lock="loading"
   element-loading-text="请求数据中"
   element-loading-background="rgba(255, 255, 255, 0.5)"
  > 
    <div class="append">
      <div class="seach">
       <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="设定仓库" name="0"></el-tab-pane>
        <el-tab-pane label="设定物流" name="1"></el-tab-pane>
        <el-tab-pane label="合并订单" name="2"></el-tab-pane>
        <el-tab-pane label="审核订单" name="3"></el-tab-pane>
        <el-tab-pane label="订单标发" name="4"></el-tab-pane>
       </el-tabs> 
       <!-- 按钮组 -->
       <div style="margin-top: 20px">
         <el-row type="flex" justify="start">
          <el-select clearable placeholder="请选择状态" v-model="pageList.state"> 
            <el-option label="启用" value="0"></el-option>
            <el-option label="禁用" value="1"></el-option>
          </el-select>
          <el-input clearable style="width: 10%; margin-right: 15px" placeholder="搜索规则名称" v-model="pageList.ruleName"></el-input>
          <el-button type="primary" icon="el-icon-search" @click="handleSearch(tabName)">查询</el-button>
          <el-button type="primary" icon="el-icon-circle-plus" @click="handleAddRule">新增规则</el-button>
          <el-button v-if="pageList.state==0" @click="handleDisable(0)">批量禁用</el-button>
          <el-button v-if="pageList.state==1" @click="handleDisable(1)">批量启用</el-button>
          <el-button style="margin-right: 10px" @click="handleBatchDele">批量删除</el-button>
          <el-link 
           type="info" 
           :underline="false" 
           style="margin: 0 20px"
           v-show="multipleSelection.length==0?false:true"
          >已选{{multipleSelection.length}}条数据</el-link>
          <el-link type="info" :underline="false">可拖动列表调整优先级顺序</el-link>
         </el-row>
       </div>
       <div style="flex:1;overflow:auto; margin-top: 30px">
         <el-table 
           border
           row-key="id"
           :data="tableList"
           @selection-change="handleSelectionChange"
           header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
         >
          <el-table-column type="selection" align="center"></el-table-column>
          <el-table-column 
           v-for="(item, index) in col"
           :key="`col_${index}`"
           :prop="dropCol[index].prop"
           :label="item.label"
           :align="item.align"
          > 
           <template slot-scope="scope">
             <el-link :underline="false" v-if="item.label=='规则名称'">{{scope.row.ruleName}}</el-link>
             <div v-else-if="item.label=='状态'">
               <span v-if="scope.row.status == '0'" style="#008000">启用</span>
               <span v-if="scope.row.status == '1'" style="#FF0000">禁用</span>
             </div>
             <p v-else>
                <span>{{scope.row[item.prop]}}</span>
             </p>
           </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-link 
               type="primary" 
               :underline="false" 
               icon="el-icon-edit"
               @click="handleEdit(scope.row)"
              >编辑</el-link>
              <el-link 
               type="danger" 
               :underline="false" 
               icon="el-icon-lock" 
               style="margin: 0 20px"
               v-if="scope.row.status == '0'"
               @click="handleUnable(scope.row)"
              >禁 用</el-link>
              <el-link 
               type="primary" 
               :underline="false" 
               icon="el-icon-unlock" 
               style="margin: 0 20px" 
               v-if="scope.row.status == '1'"
               @click="handleUnable(scope.row)"
              >启 用</el-link>
              <el-link 
               type="danger" 
               :underline="false" 
               icon="el-icon-delete"
               @click="handleDele(scope.row)"
              >删除</el-link>
            </template>
          </el-table-column>
         </el-table>
         <!-- 分页区域 -->
         <el-pagination
           @size-change="handleSizeChange"
           @current-change="handleCurrentChange"
           layout="total, sizes, prev, pager, next, jumper"
           :page-sizes="[20, 30, 50, 100]"
           :total="total"
           :page-size="pageList.pageSize"
           :current-page="pageList.current"
           style="text-align: center; margin-top: 15px"
         ></el-pagination>
       </div>
      </div>

      <!-- 新增/编辑订单规则 -->
      <el-dialog
        width="70%"
        :visible.sync="orderRuleVisible"
        modal-append-to-body="false"
      >
        <div slot="title">
          <span v-if="type == 1">新增订单规则</span>
          <span v-if="type == 2">编辑订单规则</span>
        </div>
        <div>
         <el-row :gutter="15">
          <!-- 左侧 -->
          <el-col :span="10">
             <el-card shadow="hover" style="height: 100%">
               <el-form :model="ruleForm" :rules="rulesForm" ref="ruleForm" label-width="100px">
                 <el-form-item label="规则名称：" prop="ruleName">
                   <el-input clearable v-model="ruleForm.ruleName" placeholder="请输入规则名称" style="width: 80%"></el-input>
                 </el-form-item>
                 <el-form-item label="规则类型：" prop="ruleType">
                   <el-select 
                    clearable 
                    style="width: 80%" 
                    v-model="ruleForm.ruleType" 
                    placeholder="请选择规则类型"
                    :disabled="type==2?true:false"
                   >
                     <el-option
                      v-for="item in ruleTypeList"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                     ></el-option>
                   </el-select>
                 </el-form-item>
                 <el-form-item label="规则状态：" prop="ruleState">
                   <el-select clearable v-model="ruleForm.ruleState" style="width: 80%" placeholder="请选择规则状态">
                     <el-option label="启用" value="0"></el-option>
                     <el-option label="停用" value="1"></el-option>
                   </el-select>
                 </el-form-item>
               </el-form>
               <el-divider content-position="left">可选条件</el-divider>
               <el-collapse v-model="activeNames" @change="handleChange">
                 <!-- 基础信息 -->
                 <el-collapse-item name="1">
                   <div slot="title">
                     <h4>基础信息</h4>
                   </div>
                   <el-checkbox-group v-model="checked" v-show="ruleForm.ruleType!=3?true:false">
                     <el-row :gutter="10">
                       <el-col v-for="(item,index) in checkedList" :key="index" :span="6">
                         <el-checkbox @change="changeState(item)" style="marginBottom:10px" :label="item.id" >{{item.value}}</el-checkbox>
                       </el-col>
                     </el-row>
                   </el-checkbox-group>
                   <el-checkbox-group v-model="checked" v-show="ruleForm.ruleType==3?true:false">
                     <el-row :gutter="10">
                       <el-col v-for="(item,index) in OrderTypeList" :key="index" :span="6">
                         <el-checkbox @change="changeState(item)" style="marginBottom:10px" :label="item.id">{{item.value}}</el-checkbox>
                       </el-col>
                     </el-row>
                   </el-checkbox-group>
                 </el-collapse-item>
                 <!-- 设定仓库 -->
                 <div v-show="ruleForm.ruleType=='0'?true:false">
                   <el-collapse-item name="2">
                    <div slot="title">
                      <h4>仓储物流</h4>
                    </div>
                    <el-checkbox-group v-model="isChecked">
                      <el-row :gutter="10">
                        <el-col v-for="(item,index) in checkedData" :key="index" :span="6">
                          <el-checkbox @change="change" style="marginBottom:10px" :label="item.id">{{item.value}}</el-checkbox>
                        </el-col>
                      </el-row>
                    </el-checkbox-group>
                   </el-collapse-item>
                   <el-collapse-item name="3">
                   <div slot="title">
                      <h4>订单信息</h4> 
                   </div>
                   <el-checkbox-group v-model="checkboxGroup">
                     <el-row :gutter="10">
                      <el-col v-for="(item,index) in checkOptions" :key="index" :span="6">
                       <el-checkbox @change="change" style="marginBottom:10px" :label="item.id">{{item.value}}</el-checkbox>
                      </el-col>
                     </el-row>
                   </el-checkbox-group>
                   </el-collapse-item>
                 </div>
                 <!-- 设定物流 -->
                 <div v-show="ruleForm.ruleType=='1'?true:false">
                  <el-collapse-item name="2">
                   <div slot="title">
                     <h4>仓储物流</h4>
                   </div>
                   <el-checkbox-group v-model="isChecked">
                     <el-row :gutter="10">
                       <el-col v-for="(item,index) in checkLogistics" :key="index" :span="6">
                         <el-checkbox @change="change" style="marginBottom:10px" :label="item.id">{{item.value}}</el-checkbox>
                       </el-col>
                     </el-row>
                   </el-checkbox-group>
                  </el-collapse-item>
                  <el-collapse-item name="3">
                   <div slot="title">
                     <h4>订单信息</h4> 
                   </div>
                   <el-checkbox-group v-model="checkboxGroup">
                     <el-row :gutter="10">
                      <el-col v-for="(item,index) in checkOrderInfo" :key="index" :span="6">
                       <el-checkbox @change="change" style="marginBottom:10px" :label="item.id">{{item.value}}</el-checkbox>
                      </el-col>
                     </el-row>
                   </el-checkbox-group>
                  </el-collapse-item>
                 </div>
                 <!-- 合并订单 -->
                 <div v-show="ruleForm.ruleType=='2'?true:false">
                  <el-collapse-item name="3">
                   <div slot="title">
                     <h4>订单信息</h4> 
                   </div>
                   <el-checkbox-group v-model="checkboxGroup">
                     <el-row :gutter="10">
                      <el-col v-for="(item,index) in checkMergeOrder" :key="index" :span="6">
                       <el-checkbox @change="change" style="marginBottom:10px" :label="item.id">{{item.value}}</el-checkbox>
                      </el-col>
                     </el-row>
                   </el-checkbox-group>
                  </el-collapse-item>
                 </div>
                 <!-- 审核订单 -->
                 <div v-show="ruleForm.ruleType=='3'?true:false">
                  <el-collapse-item name="2">
                   <div slot="title">
                     <h4>仓储物流</h4>
                   </div>
                   <el-checkbox-group v-model="isChecked">
                     <el-row :gutter="10">
                       <el-col v-for="(item,index) in checkOrderList" :key="index" :span="6">
                         <el-checkbox @change="change" style="marginBottom:10px" :label="item.id">{{item.value}}</el-checkbox>
                       </el-col>
                     </el-row>
                   </el-checkbox-group>
                  </el-collapse-item>
                  <el-collapse-item name="3">
                   <div slot="title">
                     <h4>订单信息</h4> 
                   </div>
                   <el-checkbox-group v-model="checkboxGroup">
                     <el-row :gutter="10">
                      <el-col v-for="(item,index) in checkOrderData" :key="index" :span="6">
                       <el-checkbox @change="change" style="marginBottom:10px" :label="item.id">{{item.value}}</el-checkbox>
                      </el-col>
                     </el-row>
                   </el-checkbox-group>
                  </el-collapse-item>
                 </div>
                 <!-- 订单标发 -->
                 <div v-show="ruleForm.ruleType=='4'?true:false">
                   <el-collapse-item name="2">
                   <div slot="title">
                     <h4>仓储物流</h4>
                   </div>
                   <el-checkbox-group v-model="isChecked">
                     <el-row :gutter="10">
                       <el-col v-for="(item,index) in checkOrderShip" :key="index" :span="6">
                         <el-checkbox @change="change" style="marginBottom:10px" :label="item.id">{{item.value}}</el-checkbox>
                       </el-col>
                     </el-row>
                   </el-checkbox-group>
                   </el-collapse-item>
                 </div>
               </el-collapse>
             </el-card>
          </el-col>
          <!-- 右侧 -->
          <el-col :span="14">
           <el-card shadow="hover" style="height: 100%">
             <el-divider content-position="left">已选条件</el-divider>
             <h4>基础信息</h4>
             <el-form :model="formInline" style="margin-top: 20px">
                 <el-form-item label="订单来源：" v-show="checked.includes(0)?true:false">
                   <el-select clearable multiple v-model="formInline.orderSource" placeholder="选择订单来源" style="width: 80%">
                     <el-option
                      v-for="item in orderSourceList"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                     ></el-option>
                   </el-select>
                 </el-form-item>
                 <el-form-item label="订单平台：" v-show="checked.includes(1)?true:false">
                   <el-select clearable multiple v-model="formInline.platform" placeholder="选择平台" style="width: 80%">
                     <el-option
                       v-for="item in orderPlatformList"
                       :key="item.id"
                       :label="item.value"
                       :value="item.id"
                     ></el-option>
                   </el-select>
                 </el-form-item>
                 <el-form-item label="订单国家：" v-show="checked.includes(2)?true:false">
                   <el-select clearable multiple v-model="formInline.country" placeholder="选择国家" style="width: 80%">
                     <el-option
                       v-for="item in orderCountryList"
                       :key="item.id"
                       :label="item.value"
                       :value="item.id"
                     ></el-option>
                   </el-select>
                 </el-form-item>
                 <el-form-item label="订单店铺：" v-show="checked.includes(3)?true:false">
                   <el-select clearable multiple v-model="formInline.shopAndMarketplaceId" placeholder="选择店铺" style="width: 80%">
                      <el-option
                       v-for="item in orderStoreList"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id+'-'+item.marketPlaceId"
                      ></el-option>
                   </el-select>
                 </el-form-item>
             </el-form>
             <h4>仓储物流</h4>
             <el-form :model="formModelList" style="margin-top: 20px" label-width="120px" label-position="right">
               <el-form-item>
                 <el-tag :key="tag" v-for="tag in dynamicTags" style="margin-right: 5px">{{tag.nameCn}}</el-tag>
               </el-form-item>
               <el-form-item label="收货国家/地区：" v-show="isChecked.includes(3)?true:false">
                 <el-link :underline="false" type="primary" @click="handleCountry">指定国家/地区</el-link>
               </el-form-item>
               <el-form-item>
                 <el-tag :key="tag" v-for="tag in tagsList" style="margin-right: 5px">{{tag.track}}</el-tag>
               </el-form-item>
               <el-form-item label="物流商：" v-show="isChecked.includes(1)?true:false">
                 <el-link :underline="false" type="primary" @click="handleProvider">指定物流商</el-link>
               </el-form-item>
               <el-form-item>
                 <el-tag :key="tag" v-for="tag in shipTrackWayTag" style="margin-right: 5px">{{tag.trackWay}}</el-tag>
               </el-form-item>
               <el-form-item label="物流方式：" v-show="isChecked.includes(2)?true:false">
                 <el-link :underline="false" type="primary" @click="handleMode">指定物流方式</el-link>
               </el-form-item>
               <el-form-item label="发货仓库：" v-show="isChecked.includes(0)?true:false">
                 <el-select clearable v-model="formModelList.shipStorageId" style="width: 80%" placeholder="选择发货仓库">
                   <el-option
                     v-for="item in storageList"
                     :key="item.id"
                     :label="item.value"
                     :value="item.id"
                   ></el-option>
                 </el-select>
               </el-form-item>
               <el-form-item label="收货省/州：" v-show="isChecked.includes(4)?true:false">
                 <el-input 
                  :rows="2" 
                  type="textarea" 
                  style="width: 80%"
                  v-model="formModelList.province"
                  placeholder="省/州信息中存在的字符, 对个规则换行输入" 
                 ></el-input>
               </el-form-item>
               <el-form-item label="收货城市：" v-show="isChecked.includes(5)?true:false">
                <el-input
                  :rows="2" 
                  type="textarea" 
                  style="width: 80%"
                  v-model="formModelList.city"
                  placeholder="城市信息中存在的字符, 对个规则换行输入" 
                ></el-input>
               </el-form-item>
               <el-form-item label="收货邮编：" v-show="isChecked.includes(6)?true:false">
                <el-row type="flex" justify="start" style="margin-bottom: 30px">
                  <el-checkbox v-model="postCodeStart">收货邮编开头</el-checkbox>
                  <el-input
                   :rows="2"
                   type="textarea"
                   :disabled="postCodeStart==true?false:true"
                   style="width: 60%; margin-left: 15px"
                   placeholder="仅支持数字,英文字母,中划线和空格,多个换行输入"
                   v-model="formModelList.postCodeStart">
                  </el-input>
                </el-row>
                <el-row type="flex" justify="start" style="margin-bottom: 30px">
                   <el-checkbox v-model="postCodeContain">收货邮编包含</el-checkbox>
                   <el-input
                    :rows="2"
                    type="textarea"
                    :disabled="postCodeContain==true?false:true"
                    style="width: 60%; margin-left: 15px"
                    placeholder="仅支持数字,英文字母,中划线和空格,多个换行输入"
                    v-model="formModelList.postCodeContain">
                   </el-input>
                </el-row>
                <el-row>
                 <el-checkbox 
                  v-model="checkedBoth"
                  :true-label = 1
                  :false-label = 0
                  :disabled="postCodeStart==true&&postCodeContain==true?false:true" 
                 >两项满足其一项</el-checkbox>
                </el-row>
               </el-form-item>
             </el-form>
             <h4>订单信息</h4>
             <el-form :model="sizeFormList" style="margin-top: 20px" :rules="sizeFormRules" ref="sizeForm" label-width="100px">
               <el-form-item label="订单MSKU：" v-show="checkboxGroup.includes('订单MSKU')?true:false">
                   <el-input
                    :rows="2"
                    type="textarea"
                    style="width: 80%"
                    placeholder="平台SKU, 多个换行输入"
                    v-model="sizeFormList.orderMsku"
                   ></el-input>
               </el-form-item>
               <el-form-item>
                 <el-tag :key="tag" v-for="tag in productListTag" style="margin-right: 5px">{{tag.sku}}</el-tag>
               </el-form-item>
               <el-form-item label="订单产品：" v-show="checkboxGroup.includes(0)?true:false">
                 <el-link type="primary" :underline="false" @click="handleProduct">指定产品</el-link>
               </el-form-item>
               <el-form-item label="包裹重量：" v-show="checkboxGroup.includes(3)?true:false">
                 <el-row :gutter="20">
                     <el-col :span="11">
                       <el-row type="flex" justify="start">
                         <el-checkbox v-model="checkedGreater" style="margin-right: 8px"></el-checkbox>
                         <el-input
                          :min="0" 
                          type="number"
                          placeholder="包裹重量" 
                          v-model="packageWeightG"
                          :disabled="checkedGreater==false?true:false"
                         >
                           <el-select
                            slot="prepend" 
                            placeholder="请选择" 
                            style="width: 100px"
                            v-model="heavierGreater" 
                            :disabled="checkedGreater==false?true:false"
                           >
                             <el-option label="大于" value="0"></el-option>
                             <el-option label="大于等于" value="1"></el-option>
                           </el-select>
                           <el-button slot="append">g</el-button>
                         </el-input>
                       </el-row>
                     </el-col>
                     <el-col :span="11">
                       <el-row type="flex" justify="start">
                         <el-checkbox v-model="checkedLess" style="margin-right: 8px"></el-checkbox>
                         <el-input 
                          :min="0"  
                          type="number" 
                          placeholder="包裹重量" 
                          v-model="packageWeightL" 
                          :disabled="checkedLess==false?true:false"
                         >
                           <el-select 
                            slot="prepend" 
                            v-model="weightLess" 
                            placeholder="请选择" 
                            style="width: 100px"
                            :disabled="checkedLess==false?true:false"
                           >
                             <el-option label="小于" value="0"></el-option>
                             <el-option label="小于等于" value="1"></el-option>
                           </el-select>
                           <el-button slot="append">g</el-button>
                         </el-input>
                       </el-row>
                     </el-col>
                 </el-row>
               </el-form-item>
               <el-form-item v-show="checkboxGroup.includes(4)?true:false">
                <span slot="label">包裹尺寸
                  <el-tooltip effect="dark" placement="top">
                    <div slot="content">
                      尺寸会按最长边, 次长边, 最短边对应比较 
                    </div>
                    <span class="el-icon-question"></span>
                  </el-tooltip>
                </span>
                <el-row :gutter="20">
                  <el-col :span="11">
                      <el-row type="flex" justify="start">
                        <el-checkbox v-model="sizeGreater" style="margin-right: 8px"></el-checkbox>
                        <el-select 
                          placeholder="请选择" 
                          style="width: 100px"
                          v-model="addInfo.Greater" 
                          :disabled="sizeGreater==false?true:false"
                        >
                          <el-option label="大于" value="0"></el-option>
                          <el-option label="大于等于" value="1"></el-option>
                        </el-select>
                        <el-input 
                          placeholder="长" 
                          style="width: 50px"
                          v-model="addInfo.productLength" 
                          :disabled="sizeGreater==false?true:false"
                        ></el-input>
                        <el-input
                          placeholder="宽"
                          style="width: 50px"
                          v-model="addInfo.productWidth"
                          :disabled="sizeGreater==false?true:false"
                        ></el-input>
                        <el-input
                          placeholder="高"
                          style="width: 50px" 
                          v-model="addInfo.productHeight"
                          :disabled="sizeGreater==false?true:false"
                        ></el-input>
                        <el-input 
                         placeholder="cm"
                         style="width: 50px"
                         :disabled="true"
                        ></el-input>
                      </el-row>
                  </el-col>
                  <el-col :span="11">
                      <el-row type="flex" justify="start">
                        <el-checkbox v-model="sizeLess" style="margin-right: 8px"></el-checkbox>
                        <el-select 
                          placeholder="请选择" 
                          style="width: 100px"
                          v-model="addInfo.smaller" 
                          :disabled="sizeLess==false?true:false"
                        >
                          <el-option label="小于" value="0"></el-option>
                          <el-option label="小于等于" value="1"></el-option>
                        </el-select>
                        <el-input 
                          placeholder="长" 
                          style="width: 50px"
                          v-model="addInfo.packLength" 
                          :disabled="sizeLess==false?true:false"
                        ></el-input>
                        <el-input
                          placeholder="宽"
                          style="width: 50px"
                          v-model="addInfo.packWidth"
                          :disabled="sizeLess==false?true:false"
                        ></el-input>
                        <el-input
                          placeholder="高"
                          style="width: 50px" 
                          v-model="addInfo.packHeight"
                          :disabled="sizeLess==false?true:false"
                        ></el-input>
                        <el-input 
                         placeholder="cm"
                         style="width: 50px"
                         :disabled="true"
                        ></el-input>
                      </el-row>
                  </el-col>
                </el-row>
               </el-form-item>
               <el-form-item label="订单总金额：" v-show="checkboxGroup.includes(5)?true:false">
                <el-row :gutter="20">
                  <!-- 订单金额大于/大于等于 -->
                  <el-col :span="11">
                       <el-row type="flex" justify="start">
                         <el-checkbox v-model="amountGreater" style="margin-right: 8px"></el-checkbox>
                         <el-select
                          placeholder="请选择"
                          style="width: 100px" 
                          v-model="addInfo.orderAmountBig"
                          :disabled="amountGreater==false?true:false"
                         >
                           <el-option label="大于" value="0"></el-option>
                           <el-option label="大于等于" value="1"></el-option>
                         </el-select>
                         <el-input
                           placeholder="订单金额" 
                           style="width: 100px"
                           v-model="addInfo.orderBigValue"
                           :disabled="amountGreater==false?true:false"
                         ></el-input>
                         <!-- 币种 -->
                         <el-select 
                          style="width: 100px" 
                          v-model="addInfo.currencyType"
                          :disabled="amountGreater==false?true:false"
                         >
                           <el-option
                            v-for="item in currencyList"
                            :key="item.id"
                            :label="item.value"
                            :value="item.id"
                           ></el-option>
                         </el-select>
                       </el-row>
                  </el-col>
                  <!-- 订单金额小于/小于等于 -->
                  <el-col :span="11">  
                       <el-row type="flex" justify="start">
                         <el-checkbox v-model="amountSmaller" style="margin-right: 8px"></el-checkbox>
                         <el-select
                          placeholder="请选择"
                          style="width: 100px" 
                          v-model="addInfo.orderAmountSmall"
                          :disabled="amountSmaller==false?true:false"
                         >
                           <el-option label="小于" value="0"></el-option>
                           <el-option label="小于等于" value="1"></el-option>
                         </el-select>
                         <el-input
                           style="width: 100px"
                           placeholder="订单金额" 
                           v-model="addInfo.orderSmallValue"
                           :disabled="amountSmaller==false?true:false"
                         ></el-input>
                         <!-- 币种 -->
                         <el-select 
                          style="width: 100px" 
                          v-model="addInfo.currencyType"
                          :disabled="amountSmaller==false?true:false"
                         >
                           <el-option
                            v-for="item in currencyList"
                            :key="item.id"
                            :label="item.value"
                            :value="item.id"
                           ></el-option>
                         </el-select>
                       </el-row>
                  </el-col>
                </el-row>
               </el-form-item>
               <el-form-item v-show="checkboxGroup.includes(8)?true:false">
                <span slot="label">发货截止时间
                 <el-tooltip effect="dark" placement="top">
                   <div slot="content">
                     计算匹配规则时的时间和发货截止时间的时间差
                   </div>
                   <span class="el-icon-question"></span>
                 </el-tooltip>
                </span>
                <el-row :gutter="20">
                  <el-col :span="11">
                       <el-row type="flex" justify="start">
                         <el-checkbox v-model="deliveryTimeG" style="margin-right: 8px"></el-checkbox>
                         <el-input placeholder="发货时间" v-model="addInfo.sendTimeGreat" :disabled="deliveryTimeG==false?true:false">
                           <el-select
                            slot="prepend" 
                            placeholder="请选择"
                            style="width: 100px"
                            v-model="addInfo.sendTimeTypeG"
                            :disabled="deliveryTimeG==false?true:false"
                           >
                             <el-option label="大于" value="0"></el-option>
                             <el-option label="大于等于" value="1"></el-option>
                           </el-select>
                           <el-button slot="append">小时</el-button>
                         </el-input>
                       </el-row>
                  </el-col>
                  <el-col :span="11">
                       <el-row type="flex" justify="start">
                         <el-checkbox v-model="deliveryTimeS" style="margin-right: 8px"></el-checkbox>
                         <el-input placeholder="发货时间" v-model="addInfo.sendTimeLower" :disabled="deliveryTimeS==false?true:false">
                           <el-select 
                            slot="prepend" 
                            placeholder="请选择" 
                            style="width: 100px"
                            v-model="addInfo.sendTimeTypeL"
                            :disabled="deliveryTimeS==false?true:false"
                           >
                             <el-option label="小于" value="0"></el-option>
                             <el-option label="小于等于" value="1"></el-option>
                           </el-select>
                           <el-button slot="append">小时</el-button>
                         </el-input>
                       </el-row>
                  </el-col>
                </el-row>
               </el-form-item>
             </el-form>
             <h4>设定动作</h4>
             <el-form :model="labelPosition" style="margin-top: 20px" label-width="100px">
                <el-form-item label="发货仓库：" v-show="ruleForm.ruleType=='0'?true:false">
                  <el-select clearable v-model="labelPosition.storageId" placeholder="选择发货仓库" style="width: 80%">
                    <el-option
                     v-for="item in storageList"
                     :key="item.id"
                     :label="item.value"
                     :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="物流方式：" v-show="ruleForm.ruleType=='1'?true:false">
                  <el-select clearable v-model="labelPosition.LogisticsMode" placeholder="选择物流方式" style="width: 80%">
                    <el-option
                     v-for="item in shipTrackWayList"
                     :key="item.id"
                     :label="item.trackWay"
                     :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="合并方式：" v-show="ruleForm.ruleType=='2'?true:false">
                  <el-select clearable v-model="labelPosition.mergerMode" placeholder="选择合并方式" style="width: 80%">
                    <el-option label="自动合并" value="0"></el-option>
                    <el-option label="不合并" value="1"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-show="ruleForm.ruleType=='3'?true:false">
                  <span slot="label">执行动作
                    <el-tooltip effect="dark" placement="top">
                      <div slot="content">
                       审核发货: 订单推送至仓库配货<br/>
                       标记待处理: 订单流入待处理页面指定的处理类型中
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <el-radio-group v-model="labelPosition.performWay">
                    <el-radio label="0">审核发货</el-radio>
                    <el-radio label="1">标记待处理</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="标发规则：" v-show="ruleForm.ruleType=='4'?true:false">
                  <el-select 
                   clearable
                   style="width: 30%" 
                   placeholder="请选择标发规则"
                   v-model="labelPosition.regulation" 
                  >
                    <el-option label="自动标发" value="0"></el-option>
                    <el-option label="订单发货后再标发" value="1"></el-option>
                    <el-option label="不标发" value="2"></el-option>
                  </el-select>
                </el-form-item>
             </el-form>
             <div v-show="labelPosition.performWay=='1'&&ruleForm.ruleType=='3'?true:false" style="float: left">
                <el-form :model="dynamicValidateForm" label-width="30%">
                  <el-form-item label="处理类型">
                   <el-select 
                    clearable
                    style="width: 260px" 
                    placeholder="选择处理类型" 
                    v-model="labelPosition.dealType"
                   >
                     <el-option
                      v-for="item in waitDealType"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                     ></el-option>
                   </el-select>
                  </el-form-item>
                  <el-form-item label="订单标签">
                   <el-select 
                    clearable
                    multiple
                    style="width: 260px" 
                    placeholder="选择订单标签" 
                    v-model="labelPosition.orderLabel"
                   >
                     <el-option
                      v-for="item in lableList"
                      :key="item.id"  
                      :label="item.value"
                      :value="item.id"
                     ></el-option>
                   </el-select>
                  </el-form-item>
                </el-form>
             </div>
           </el-card>
          </el-col>
        </el-row>
        </div>
        <span slot="footer">
         <el-button @click="orderRuleVisible = false">取 消</el-button>
         <el-button v-if="type == 1" type="primary" @click="handleConfirm(1)">确 定</el-button>
         <el-button v-if="type == 2" type="primary" @click="handleConfirm(2)">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 指定国家/地区 -->
      <el-dialog
       width="40%"
       title="收货国家/地区"
       :visible.sync="countryVisible"
      >
        <el-row style="margin-bottom: 20px">
          <el-input 
           clearable
           @clear="clearInputValue"
           style="width: 30%; margin-right: 15px"
           v-model="pageInfo.keyword"
           placeholder="搜索国家/地区二字代码 英文名 中文名"
          ></el-input> 
        </el-row>
        <el-table 
          border 
          height="600px"
          :data="countryList"
          @selection-change="handleSelectionToggle"
          header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
        >
          <el-table-column 
           align="center"
           type="selection" 
          ></el-table-column>
          <!-- :selectable="checkBoxDisable" -->
          <!-- :selectable="(row) => {return !multipleCountry.find((v) => v.id == row.id);}" -->
          <el-table-column label="二字代码" prop="iso31661" align="center"></el-table-column>
          <el-table-column label="国家/地区英文名" prop="nameEn" align="center"></el-table-column>
          <el-table-column label="国家/地区" prop="nameCn" align="center"></el-table-column>
        </el-table>
        <span slot="footer">
          <el-button @click="countryVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleConfirmRegion">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 物流商 -->
      <el-dialog
       width="30%"
       title="物流商"
       :visible.sync="shipTrackVisible"
      >
        <el-table 
         border 
         :data="shipTrackList"
         @selection-change="handleSelectionTrack"
         header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
        >
          <el-table-column align="center" type="selection"></el-table-column>
          <el-table-column label="物流商" prop="value" align="center"></el-table-column>
        </el-table>
        <span slot="footer">
          <el-button @click="shipTrackVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleShipTrack">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 物流方式 -->
      <el-dialog
        width="40%"
        title="物流方式"
        :visible.sync="shipTrackWayVisible"
      >
        <el-table 
         border 
         :data="shipTrackWayList"
         @selection-change="handleSelectionTrackWay"
        >
          <el-table-column 
           align="center"
           type="selection" 
          ></el-table-column>
          <el-table-column label="物流商" prop="track" align="center"></el-table-column>
          <el-table-column label="物流方式" prop="trackWay" align="center"></el-table-column>
        </el-table>
        <span slot="footer">
          <el-button @click="shipTrackWayVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleShipTrackWay">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 选择产品 -->
      <el-dialog
       width="40%"
       title="添加商品"
       :visible.sync="productVisible"
      >  
       <el-row type="flex" justify="start" style="margin-bottom: 15px">
         <el-input 
          clearable
          @clear="handleProduct()"
          placeholder="搜索品名/SKU" 
          v-model="pageGoodsInfo.keyword"
          style="width: 20%; margin-right: 15px"
         ></el-input>
         <el-button type="primary" icon="el-icon-search" @click="handleProduct">查询</el-button>
       </el-row>  
       <el-table 
        border 
        height="600px"
        :data="addGoodsList"
        ref="multipleTable"
        @selection-change="handleSelectionProduct"
        header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
       >
         <el-table-column type="selection" align="center"></el-table-column>
         <el-table-column label="图片" align="center">
           <template slot-scope="scope">
             <img :src="scope.row.productImagePath" alt="" style="width:80px; height: 60px">
           </template>
         </el-table-column>
         <el-table-column label="品名" prop="commodityName" align="center" :show-overflow-tooltip="true"></el-table-column>
         <el-table-column label="SKU" prop="commoditySku" align="center"></el-table-column>
         <el-table-column label="分类" prop="productTypeName" align="center"></el-table-column>
       </el-table>
       <!-- 分页区域 -->
       <el-pagination
         @size-change="handleSizeProduct"
         @current-change="handleCurrentProduct"
         layout="total, sizes, prev, pager, next, jumper"
         :page-sizes="[20, 30, 50, 100]"
         :total="productTotal"
         :page-size="pageGoodsInfo.pageSize"
         :current-page="pageGoodsInfo.current"
         style="text-align: center; margin-top: 15px"
       ></el-pagination>
       <span slot="footer">
        <el-button @click="productVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirmProduct">确 定</el-button>
       </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  addInit,   // 新增初始化
  handleAddRule,  // 新增订单规则
  listPage,   // 查询分页
  pageInit,   // 查询初始化
  batchDelete,  // 批量删除
  updateStatus, // 更新状态
  updateInit,   // 编辑初始化
  updateRule,   // 编辑事件
  handleSort,   // 排序事件
} from'@/api/shipManage/orderRules.js'

import {
  getProductListPage,  // 商品查询 (分页)
}from '@/api/procurementManage/procurementPlan.js'
import Sortable from 'sortablejs'
export default {
  data() {
    return {
      total: 0,  // 总条数
      productTotal: 0,
      type: 0,   // 1: 为新增  2: 为编辑
      activeName: "0",  // 设定仓库
      activeNames: ['1', '2', '3'],
      tabName: 0,
      loading: false,
      sizeGreater: false,  // 尺寸较大
      orderRuleVisible: false,    // 订单规则
      shipTrackVisible: false,    // 物流商
      shipTrackWayVisible: false, // 物流方式
      multipleSelection: [],
      // 左侧状态
      ruleForm: {
        ruleName: "",
        ruleType: "",
        ruleState: "0",   // 规则状态
      },
      addInfo: {
        Greater: "",  // 大于
        productLength: "", // 长
        productWidth: "",     // 宽
        productHeight: "",    // 高
        smaller: "",  // 小于
        packLength: "",
        packWidth: "",
        packHeight: "",
        orderAmountBig: "",  // 订单金额较大
        orderBigValue: "", 
        currencyType: "",    // 币种类型 
        orderAmountSmall: "", // 订单金额较小
        orderSmallValue: "",  // 订单金额较小
        sendTimeGreat: "",    // 发货时间大于
        sendTimeLower: "",    // 发货时间小于
        sendTimeTypeG: "",    // 发货时间较大下拉框
        sendTimeTypeL: "",    // 发货时间较小下拉框
      },
      amountSmaller: "",
      amountGreater: false, // 订单总金额
      checkedLess: false,   // 选择小于
      checkedGreater: false,
      sizeLess: false,
      deliveryTimeG: false,  // 发货时间大于
      deliveryTimeS: false,  // 发货时间小于
      heavierGreater: "",    // 重量较大下拉框
      weightLess: "",        // 重量较小下拉框
      currencyList: [],      // 币种集合
      tagsList: [],
      dynamicTags: [],
      checked: [],
      ruleTypeList: [],
      orderSourceList: [],
      multipleTrackWayList: [],
      storageList: [],
      checkedList: [    // (设定仓库)基础信息
        {id: 1, value: "订单平台"},
        {id: 2, value: "订单国家"},
        {id: 3, value: "订单店铺"}
      ],
      OrderTypeList: [  // (审核订单)基础信息
        {id: 0, value: "订单来源"},
        {id: 1, value: "订单平台"},
        {id: 2, value: "订单国家"},
        {id: 3, value: "订单店铺"}
      ],
      isChecked: [],
      checkedData: [   // (设定仓库) 仓储物流
        {id: 3, value: "收货国家/地区"},
        {id: 4, value: "收货省/州"},
        {id: 5, value: "收货城市"},
        {id: 6, value: "收货邮编"},
      ],
      checkLogistics: [ // (设定仓库) 仓储物流
        {id: 0, value: "发货仓库"},
        {id: 3, value: "收货国家/地区"},
        {id: 4, value: "收货省/州"},
        {id: 5, value: "收货城市"},
        {id: 6, value: "收货邮编"},
      ],
      checkOrderList: [  // 审核订单
        {id: 0, value: "发货仓库"},
        {id: 1, value: "物流商"},
        {id: 2, value: "物流方式"},
        {id: 3, value: "收货国家/地区"},
        {id: 4, value: "收货省/州"},
        {id: 5, value: "收货城市"},
        {id: 6, value: "收货邮编"},
      ],
      checkMergeOrder: [   // (合并订单) 订单信息
        {id: 0, value: "订单产品"},
        {id: 5, value: "订单金额"},
        {id: 4, value: "包裹尺寸"},
        {id: 3, value: "包裹重量"}
      ],
      checkOptions: [     // 设定仓库
        {id: 0, value: "订单MSKU"},
        {id: 1, value: "订单产品"},
      ],
      checkOrderInfo: [   // 设定物流
        {id: 2, value: "订单MSKU"},
        {id: 0, value: "订单产品"},
        {id: 4, value: "包裹尺寸"},
        {id: 3, value: "包裹重量"},
        {id: 5, value: "订单金额"},
      ],
      checkOrderData: [   // 审核订单
        {id: 0, value: "订单产品"},
        {id: 3, value: "包裹重量"},
        {id: 4, value: "包裹尺寸"},
        {id: 5, value: "订单金额"},
        {id: 8, value: "发货截止时间"},
      ],
      checkOrderShip: [   // 订单标发
        {id: 0, value: "发货仓库"},
        {id: 1, value: "物流商"},
        {id: 2, value: "物流方式"},
      ],
      shipTrackList: [],   // 物流商
      shipTrackWayList: [],  // 物流方式
      shipTrackWayTag: [],
      dynamicValidateForm: [],
      packageWeightG: "",   // 包裹尺寸重大于
      packageWeightL: "",   // 包裹尺寸重小于
      checkboxGroup: [],
      multipleTrackList: [],
      orderPlatformList: [],
      orderCountryList: [],
      orderStoreList: [],
      formInline: {
        orderSource: [],  // 订单来源
        platform: [],     // 订单平台
        country: [],      // 订单国家
        shopAndMarketplaceId: [] // 店铺+站点
      },
      formModelList: {
        shipStorageId: "",    // 发货仓库
        province: "", // 省/州
        city: "",     // 城市
        postCodeStart: "",   // 邮编开头
        postCodeContain: "", // 邮编包含
      },
      checkedBoth: "",     // 两项选择其一
      postCodeStart: false,   // 邮编开头
      postCodeContain: false,  // 邮编包含
      sizeFormList: {
        orderMsku: "",
      },
      labelPosition: {
        storageId: "",  // 仓库Id 
        LogisticsMode: "",  // 物流方式
        mergerMode: "",  // 合并方式
        performWay: "0",  // 执行动作
        regulation: "0", // 自动标发
        dealType: "",    // 处理类型 
        orderLabel: []   // 订单标签
      },
      rulesForm: {
        ruleName: [
          { required: true, message: '请输入规则名称', trigger: 'blur' },
        ],
        ruleType: [
          { required: true, message: '请输入规则类型', trigger: 'blur' },
        ],
        ruleState: [
          { required: true, message: '请输入规则状态', trigger: 'blur' },
        ]
      },
      col: [
        { label: '规则名称', prop: 'ruleName', align:"center"},
        { label: '状态', prop: 'status', align:"center"},
        { label: '操作人', prop: 'createBy', align:"center" },
        { label: '操作时间', prop: 'operateTime', align:'center' }
      ],
      dropCol: [
        { label: '规则名称', prop: 'ruleName' },
        { label: '状态', prop: 'status' },
        { label: '操作人', prop: 'createBy' },
        { label: '操作时间', prop: 'operateTime' }
      ],
      tableList: [],
      pageList: {
        curent: 1,
        pageSize: 20, // 每页显示条数
        state: "0",    // 状态
        ruleName: "", // 规则名称
      },
      pageInfo: {
        keyword: "",  // 关键词
      },
      pageGoodsInfo: {
        keyword: "",  // 关键词
        current: 1,
        pageSize: 20, // 每页条数
      },
      countryVisible: false,
      countryList: [],  // 国家集合
      multipleCountry: [], 
      productVisible: false,
      addGoodsList: [],  // 添加商品列表
      multipleSelectionGoods: [],  // 多选商品
      productListTag: [],
      lableList: [],    // 标签集合
      waitDealType: [], // 处理类型
      currentRowId: "", // 编辑当前行的id
    }
  },
  created() {
    this.getPageInit();   // 查询初始化
    this.handleSearch(0);  // 查询事件
  },
  mounted() {
    // 阻止默认行为
	  document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
    this.rowDrop();
    // this.columnDrop();
  },
  watch:{
    'pageInfo.keyword':function(val,oldval){
      let countryArray = [];
      this.countryList.forEach(item=> {
        if(val == item.nameCn) {
          let object = {
            iso31661: item.iso31661,
            nameEn: item.nameEn,
            nameCn: item.nameCn
          }
          countryArray.push(object);
        }
      })
      this.countryList = countryArray;
    }, // 键路径
  },
  methods: {
    //行拖拽
    rowDrop() {
      const tbody = document.querySelector('.el-table__body-wrapper tbody')
      const _this = this
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.tableList.splice(oldIndex, 1)[0];  // 当前拖拽行
          _this.tableList.splice(newIndex, 0, currRow);   //  拖拽之后的列排序
          let sortedIds = [];
          sortedIds = _this.tableList.map(v=> v.id);
          _this.handleProceSort(currRow, sortedIds);  // 排序接口
        }
      })
    },
    handleProceSort(currRow, sortedIds) {
      let data = {
        currentId: currRow.id,
        ids: sortedIds,
        type: this.pageList.state
      }
      handleSort(data)
      .then(res=> {
        console.log(res);
        if(res.data.code == 500) {
         this.$message.error(res.data.message);
        }else {
          this.$message.success(res.data.message);
        }
      })
    },
    //列拖拽
    // columnDrop() {
    //   const wrapperTr = document.querySelector('.el-table__header-wrapper tr')
    //   this.sortable = Sortable.create(wrapperTr, {
    //     animation: 180,
    //     delay: 0,
    //     onEnd: evt => {
    //       const oldItem = this.dropCol[evt.oldIndex]
    //       this.dropCol.splice(evt.oldIndex, 1)
    //       this.dropCol.splice(evt.newIndex, 0, oldItem)
    //     }
    //   })
    // },
    clearInputValue() {
      console.log(this.countryList);
    },
    handleClick(tab) {
      this.tabName = parseInt(tab.name);
      if(tab.name == '0') {  // 设定仓库
        this.handleSearch(0);
      }else if(tab.name == '1') {  // 设定物流
        this.handleSearch(1);
      }else if(tab.name == '2') {  // 合并订单
        this.handleSearch(2);
      }else if(tab.name == '3') {  // 审核订单
        this.handleSearch(3);
      }else if(tab.name == '4') {  // 订单标发
        this.handleSearch(4);
      }
    },
    getPageInit() {
      pageInit()
      .then(res=> {
        console.log(res, 'gggggggggggg初始化信息');
      })
    },
    // 查询事件
    handleSearch(tabName) {
      this.loading = true;
      let params = {
        type: tabName,  // 规则类型
        status: this.pageList.state,   // 规则状态
        keyword: this.pageList.ruleName, // 规则名称
        current: this.pageList.curent,
        pageSize: this.pageList.pageSize, 
      }
      listPage(params)
       .then(res=> {
         this.loading = false;
         this.tableList = [];
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.tableList = res.data.values;
           this.total = res.data.pageInfo.total;
         }
      })
    },
    handleSizeChange(newSize) {
      this.pageList.pageSize = newSize;
      this.handleSearch();
    },
    handleCurrentChange(newCurrent) {
      this.pageList.curent = newCurrent;
      this.handleSearch();
    },  
    // 编辑初始化
    handleEdit(row) {
      this.currentRowId = row.id;
      this.type = 2;   // 弹框标题
      updateInit(row.id)
       .then(res=> {
         console.log(res.data.data, 'hahahhahahahahhaha');
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           // 数据赋值
           this.ruleTypeList = res.data.data.map.data.ruleTypeList;  // 规则类型数据
           this.orderSourceList = res.data.data.map.data.orderFrom;  // 订单来源数据
           this.orderPlatformList = res.data.data.map.data.orderPlatformList;  // 订单平台数据
           this.orderCountryList = res.data.data.map.data.countryList;  // 订单国家数据
           this.orderStoreList = res.data.data.map.data.shopList;       // 订单店铺数据
           this.countryList = res.data.data.map.data.shipCountryList;   // 指定国家/地区
           this.shipTrackList = res.data.data.map.data.shipTrackList;   // 指定物流商
           this.shipTrackWayList = res.data.data.map.data.shipTrackWayList;  //  指定物流方式
           this.storageList = res.data.data.map.data.storageList;  // 发货仓库
           this.waitDealType = res.data.data.map.data.dealList;    // 处理类型
           this.lableList = res.data.data.map.data.labList;        // 标签集合
           let result = res.data.data.detail;
           this.ruleForm.ruleName = result.ruleName;            // 规则名称
           this.ruleForm.ruleType = parseInt(result.ruleType);  // 规则类型
           this.ruleForm.ruleState = (result.ruleStatus).toString();  // 规则状态
           // 左侧数据勾选
           this.checked = res.data.data.detail.chooses.orderList;  // 订单列表
           this.checkboxGroup = res.data.data.detail.chooses.productList;  // 订单信息
           this.isChecked = res.data.data.detail.chooses.warhoseList;    // 仓储物流
           // 基础信息
           this.formInline.orderSource = result.orderType.split(',').map(Number);  // 订单来源
           this.formInline.platform = result.orderPlatform.split(',').map(Number); // 订单平台
           this.formInline.country = result.orderCountry.split(',').map(Number);   // 订单国家
           this.formInline.shopAndMarketplaceId = result.orderShop.split(',');     // 订单店铺
           // 仓储物流 
           let shipCountryArray = [];
           result.shipCountryList.forEach(data=> {
             let object = {
               id: data.id,
               nameCn: data.value
             }
             shipCountryArray.push(object)
           })
           this.dynamicTags = shipCountryArray;  // 收货国家/地区
           let trackProvider = [];
           result.trackList.forEach(data=> {
             let object = {
               id: data.id,
               track: data.value
             }
             trackProvider.push(object)
           })
           this.tagsList = trackProvider;  // 物流商
           let trackWayArray = [];
           result.trackWayList.forEach(data=> {   // 物流方式
             let object = {
               id: data.id,
               trackWay: data.value
             }
             trackWayArray.push(object);
           })
           this.shipTrackWayTag = trackWayArray;    // 物流方式
           this.formModelList.shipStorageId = result.shipStorageId?parseInt(result.shipStorageId):"";  // 发货仓库
           this.formModelList.province = result.shipStateContains;   // 发货省/州
           this.formModelList.city = result.shipCityContains;        // 收货城市
           this.formModelList.postCodeStart = result.shipPostcodeStart;  // 收货邮编开头
           this.postCodeStart = result.shipPostcodeStart?true:false;
           this.formModelList.postCodeContain = result.shipPostcodeContains;  // 收货邮编包含
           this.postCodeContain = result.shipPostcodeContains?true:false;
           this.checkedBoth = result.shipPostcodeType;    // 两者满足其一项
           result.sku.forEach(data=> {   // 订单产品
             let object = { 
               id: data.id,
               sku: data.value
             }
             this.productListTag.push(object);
           })
           // 订单信息
           this.heavierGreater = (result.packageWeightTypeG).toString();  // 重量较大下拉框
           this.weightLess = (result.packageWeightTypeL).toString();      // 重量较小下拉框
           this.packageWeightG = result.packageWeightG;      // 重量较大数值
           this.packageWeightL = result.packageWeightL;      // 重量较小的值
           this.checkedGreater = result.packageWeightTypeG||result.packageWeightG?true:false;
           this.checkedLess = result.packageSizeTypeL||result.packageWeightL?true:false;
           this.addInfo.Greater = (result.packageSizeTypeG).toString();  // 包裹尺寸较大下拉框
           this.addInfo.productLength = result.packageLengthG;  // 包裹尺寸长度较大
           this.addInfo.productWidth = result.packageWidthG;   // 包裹尺寸宽度较大
           this.addInfo.productHeight = result.packageHeightG;  // 包裹尺寸高度较大
           this.addInfo.smaller = (result.packageSizeTypeL).toString();  // 包裹尺寸较小下拉框
           this.addInfo.packLength = result.packageLengthL;    // 包裹尺寸长度较小
           this.addInfo.packWidth = result.packageWidthL;      // 包裹尺寸宽度较小
           this.addInfo.packHeight = result.packageHeightL;    // 包裹尺寸高度较小
           this.sizeGreater = result.packageSizeTypeG||result.packageLengthG||result.packageWeightG||result.packageHeightG?true:false;  // 包裹尺寸较大
           this.sizeLess = result.packageSizeTypeL||result.packageLengthL||result.packageWeightL||result.packageHeightL?true:false;     // 包裹尺寸较大
           this.addInfo.orderAmountBig = (result.orderPriceTypeG).toString();  // 订单总金额较大下拉款
           this.addInfo.orderBigValue = result.orderPriceG;       // 订单金额较大值
           this.addInfo.orderAmountSmall = (result.orderPriceTypeL).toString();  // 订单总金额较小下拉框
           this.addInfo.currencyType = parseInt(result.currencyType);   // 币种
           this.addInfo.orderSmallValue  = result.orderPriceL;      // 订单总金额较小值
           this.amountGreater = result.orderPriceTypeG||result.orderPriceG?true:false;
           this.amountSmaller = result.orderPriceTypeL||result.orderPriceL?true:false;
           this.addInfo.sendTimeTypeG = (result.sendTimeTypeG).toString(); // 截止发货时间大于
           this.addInfo.sendTimeGreat = result.sendTimeG;     // 发货时间大于
           this.addInfo.sendTimeTypeL = (result.sendTimeTypeL).toString(); // 发货时间小于
           this.addInfo.sendTimeLower = result.sendTimeL;     // 发货时间小于值
           this.deliveryTimeG = result.sendTimeTypeG||result.sendTimeG?true:false;
           this.deliveryTimeS = result.sendTimeTypeL||result.sendTimeL?true:false;
           this.currencyList = res.data.data.map.data.currencyList;  // 币种集合
           // 设定动作
           if(this.ruleForm.ruleType=='0') {
             this.labelPosition.storageId = parseInt(result.executeType);
           }else if(this.ruleForm.ruleType=='1') {
             this.labelPosition.LogisticsMode = (result.executeType).toString();
           }else if(this.ruleForm.ruleType=='2') {
             this.labelPosition.mergerMode = (result.executeType).toString();
           }else if(this.ruleForm.ruleType=='3') {     // 审核订单
             this.labelPosition.performWay = (result.executeType).toString();
             if(this.labelPosition.performWay =='1') {  // 标记待处理
              this.labelPosition.dealType = parseInt(result.waitDealType);
              this.labelPosition.orderLabel = result.orderLabel.split(',').map(Number);
             }
           }else if(this.ruleForm.ruleType=='4') {
             this.labelPosition.regulation = (result.executeType).toString();
           }
         }
       })
      this.orderRuleVisible = true;
    },
    // 新增/编辑订单规则
    handleConfirm(type) {
      if(!this.ruleForm.ruleName) {
        this.$message.error('请输入规则名称');
        return false;
      }
      this.loading = true;
      let executeType = "";
      if (this.ruleForm.ruleType == 0) {   // 设定仓库
        executeType = this.labelPosition.storageId;
      } else if (this.ruleForm.ruleType == 1) {   // 设定物流
        executeType = this.labelPosition.LogisticsMode;
      } else if (this.ruleForm.ruleType == 2) {   // 合并订单
        executeType = this.labelPosition.mergerMode;
      } else if (this.ruleForm.ruleType == 3) {   // 审核订单
        executeType = this.labelPosition.performWay;
      }else if (this.ruleForm.ruleType == 4) {    // 订单标发
        executeType = this.labelPosition.regulation;
      }
      // 组装请求参数
      let data = {
        ruleName: this.ruleForm.ruleName,    // 规则名称
        ruleType: this.ruleForm.ruleType,    // 规则类型
        ruleStatus: this.ruleForm.ruleState, // 规则状态
        orderFrom: this.formInline.orderSource.join(','),  // 订单来源
        orderPlatform: this.formInline.platform.join(','), // 订单平台
        orderCountry: this.formInline.country.join(','),   // 订单国家
        shopAndMarketplaceId: this.formInline.shopAndMarketplaceId.join(','), // 店铺/站点
        shipCountry: this.dynamicTags.map(e=> e.id).join(','),    // 收货国家/地区
        shipTrackId: this.tagsList.map(v=> v.id).join(','),  // 物流商
        shipTrackWayId: this.shipTrackWayTag.map(g=> g.id).join(','), // 物流方式
        shipStorageId: this.formModelList.shipStorageId,   // 发货仓库
        shipStateContains: this.formModelList.province,  // 收货省/州
        shipCityContains: this.formModelList.city,   //  收货城市
        shipPostcodeStart: this.formModelList.postCodeStart,  // 收货邮编开头
        shipPostcodeContains: this.formModelList.postCodeContain,  // 收货邮编包含
        shipPostcodeType: this.checkedBoth,       // 勾选为1,  不勾选为0
        productId: this.productListTag.map(v=> v.id).join(','),   // 指定产品
        packageWeightTypeG: this.heavierGreater,  // 重量较大下拉框
        packageWeightTypeL: this.weightLess,      // 重量较小下拉框
        packageWeightG: this.packageWeightG,      // 包裹重量较大
        packageWeightL: this.packageWeightL,      // 包裹重量较小
        packageSizeTypeG: this.addInfo.Greater,   // 包裹尺寸较大下拉框
        packageSizeTypeL: this.addInfo.smaller,   // 包裹尺寸较小下拉框
        packageLengthG: this.addInfo.productLength,  // 包裹尺寸长大于
        packageLengthL: this.addInfo.packLength,     // 包裹尺寸长小于
        packageWidthG: this.addInfo.productWidth,    // 包裹尺寸宽大于
        packageWidthL: this.addInfo.packWidth,       // 包裹尺寸宽小于
        packageHeightG: this.addInfo.productHeight,  // 包裹尺寸高大于
        packageHeightL: this.addInfo.packHeight,     // 包裹尺寸高小于
        orderPriceTypeG: this.addInfo.orderAmountBig,   // 订单总金额较大下拉框
        orderPriceTypeL: this.addInfo.orderAmountSmall, // 订单总金额较小下拉款
        orderPriceG: this.addInfo.orderBigValue,    // 订单总金额大于
        orderPriceL: this.addInfo.sendTimeLower,    // 订单总金额小于
        currencyType: this.addInfo.currencyType,       // 币种
        sendTimeTypeG: this.addInfo.sendTimeTypeG,  // 发货时间较大下拉框
        sendTimeTypeL: this.addInfo.sendTimeTypeL,  // 发货时间较小下拉框
        sendTimeG: this.addInfo.sendTimeGreat,      // 发货时间大于
        sendTimeL: this.addInfo.sendTimeLower,      // 发货时间小于
        orderLabel: this.labelPosition.performWay=="1"?this.labelPosition.orderLabel.join(','):"",  // 订单标签
        waitDealType: this.labelPosition.performWay=="1"?this.labelPosition.dealType:"",   // 处理类型
        executeType: executeType,   // 执行动作
        id: this.type==2?this.currentRowId:null
      }
      if(type == 1) {
        // 加载状态
        handleAddRule(data)
        .then(res=> {
          this.loading = false;
          if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }else {
            this.$message.success(res.data.message);
            this.orderRuleVisible = false;
            this.handleSearch(this.tabName);  // 刷新数据
          }
        })
      }else if(type == 2) {
        this.loading = false;
        updateRule(data)
         .then(res=> {
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.$message.success('编辑成功');
             this.orderRuleVisible = false
             this.handleSearch(this.tabName);  // 刷新数据
           }
         })
      }
    },
    // 新增规则
    handleAddRule() {
      this.type =1;
      if(this.tabName == 0) {        // 设定仓库
        this.ruleForm.ruleType = 0;
      }else if(this.tabName == 1) {  // 设定物流
        this.ruleForm.ruleType = 1;
      }else if(this.tabName == 2) {  // 合并订单
        this.ruleForm.ruleType = 2;  
      }else if(this.tabName == 3) {  // 审核订单
        this.ruleForm.ruleType = 3;
      }else if(this.tabName == 4) {  // 订单标发
        this.ruleForm.ruleType = 4;
      }
      addInit()
      .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          let _this = this
          _this.ruleTypeList = res.data.data.ruleTypeList;   // 规则类型
          _this.countryList = res.data.data.shipCountryList; // 收货国家/地区
          _this.orderSourceList = res.data.data.orderFrom;   // 订单来源
          _this.orderPlatformList = res.data.data.orderPlatformList;  // 订单平台
          _this.orderCountryList = res.data.data.countryList;   // 订单国家
          _this.orderStoreList = res.data.data.shopList;
          _this.storageList = res.data.data.storageList;  // 仓库列表
          _this.shipTrackList = res.data.data.shipTrackList;
          _this.shipTrackWayList = res.data.data.shipTrackWayList;  // 物流方式
          _this.lableList = res.data.data.labList;      //  标签集合
          _this.waitDealType = res.data.data.dealList;  //  处理类型
          _this.currencyList = res.data.data.currencyList;  // 集币种集合
        }  
      })
      // 数据初始化
      this.ruleForm.ruleName = "";  // 规则名称
      this.checked = [];            // 基础信息(勾选清空)
      this.isChecked = [];          // 仓储物流(...)
      this.checkboxGroup = [];      // 订单信息(...)
      this.formInline.orderSource = [];  // 订单来源
      this.formInline.platform = [];     // 订单平台
      this.formInline.country = [];      // 订单国家
      this.formInline.shopAndMarketplaceId = [];  // 订单店铺
      this.dynamicTags = [];        // 收货国家/地区
      this.tagsList = [];           // 物流商
      this.shipTrackWayTag = [];    // 物流方式
      this.formModelList.shipStorageId = "";  // 发货仓库
      this.formModelList.province = "";   // 收货省/州
      this.formModelList.city = "";    // 收货城市 
      this.postCodeStart = false;
      this.postCodeContain = false; 
      this.formModelList.postCodeStart = "";   // 收货邮编开头
      this.formModelList.postCodeContain = "";  // 收货邮编包含
      // 订单信息
      this.productListTag = [];    // 指定产品
      this.checkedGreater = false; // 重量较大者
      this.heavierGreater = "";    // 重量较小者(下拉框)
      this.packageWeightG = "";    // 重量较大者值(输入框)
      this.checkedLess = false;
      this.packageWeightL = "";    // 重量包裹较小者(输入框)
      this.weightLess = "";   
      this.sizeGreater = false;
      this.addInfo.Greater = "";   // 包裹尺寸(下拉框)
      this.addInfo.productLength = "";  // 长度较大者
      this.addInfo.productWidth = "";   // 宽度较大者
      this.addInfo.productHeight = "";  // 高度较大者
      this.sizeLess = false;
      this.addInfo.smaller = "";        // 尺寸较小者
      this.addInfo.packLength = "";     // 长度较小者
      this.addInfo.packWidth = "";      // 宽度较小者
      this.addInfo.packHeight = "";     // 高度较小者
      this.amountGreater = false;       // 金额较大者
      this.addInfo.orderAmountBig = ""; // 金额较大下拉框
      this.addInfo.orderBigValue = "";  
      this.amountSmaller = false;
      this.addInfo.orderAmountSmall = "";
      this.addInfo.orderSmallValue = "";
      this.deliveryTimeG = false;
      this.addInfo.sendTimeGreat = "";
      this.addInfo.sendTimeTypeG = "";
      this.deliveryTimeS = false;
      this.addInfo.sendTimeLower = "";
      this.addInfo.sendTimeTypeL = "";
      this.addInfo.currencyType = "";   // 币种为空
      // 设定动作 
      this.labelPosition.storageId = "";     // 发货仓库
      this.labelPosition.LogisticsMode = ""; // 物流方式
      this.labelPosition.mergerMode = "";    // 合并方式
      this.labelPosition.regulation = "";    // 标发规则
      this.labelPosition.dealType = "";      // 订单处理类型
      this.labelPosition.orderLabel = [];    // 订单标签
      this.orderRuleVisible = true;
    },
    // 启用/禁用操作
    handleUnable(row) {
      let ids = [];
      ids.push(row.id);
      this.$confirm(`你确定要${row.status == 1 ? "启用": "禁用"}吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          ids: ids,
          type: row.status==0?1:0
        }
        updateStatus(data)
        .then(res=> {
          if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }else {
            this.$message.success(res.data.message);
            this.handleSearch(this.tabName);
          }
        })
      }).catch(() => {});
    },
    // 删除操作
    handleDele(row) {
      let ids = [];
      ids.push(row.id);
      this.$confirm('确认要删除?删除后不可恢复', '删除规则', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          ids: ids
        }
        batchDelete(data)
         .then(res=> {
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.$message.success(res.data.message);
             this.handleSearch(this.tabName);    // 刷新列表
           }
        })
      }).catch(() => {});
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 指定物流商
    handleProvider() {
      this.shipTrackVisible = true;
    },
    // 指定物流方式
    handleMode() {
      this.shipTrackWayVisible = true;
    },
    handleSelectionTrack(val) {
      this.multipleTrackList = val;
    },
    // 新增物流商
    handleShipTrack() {
      this.multipleTrackList.forEach(data=> {
        let object = {
          id: data.id,
          track: data.value
        }
        this.tagsList.push(object)
      })
      this.shipTrackVisible = false;
    },
    handleSelectionTrackWay(val) {
      this.multipleTrackWayList = val;
    },
    // 新增物流方式
    handleShipTrackWay() {
      this.multipleTrackWayList.forEach(item=> {
       let object = {
         id: item.id,
         trackWay: item.trackWay
       }
       this.shipTrackWayTag.push(object);
      })
      this.shipTrackWayVisible = false;
    },
    handleChange(val) {

    },
    // 批量禁用
    handleDisable(state) {
      let ids = [];
      ids = this.multipleSelection.map(t=> t.id);
      if(!this.multipleSelection.length) {
        this.$message.error('请勾选需要操作的选项');
        return false;
      }
      this.$confirm(`共${this.multipleSelection.length}个规则, 确认要${state == 1 ? "启用": "禁用"}?`, `批量${state == 1 ? "启用": "禁用"}规则`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          ids: ids,
          type: state==0?1:0
        }
        updateStatus(data)
         .then(res=> {
           console.log(res);
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.$message.success(res.data.message);
             this.handleSearch(this.tabName);
           }
         })
      }).catch(() => {});
    },
    // 批量删除
    handleBatchDele() {
      let ids = [];
      ids = this.multipleSelection.map(s=> s.id);  
      if(!this.multipleSelection.length) {
        this.$message.error('请勾选需要操作选项');
        return false;
      }
      this.$confirm(`共${this.multipleSelection.length}个规则, 确认删除?删除后不可恢复`, '批量删除规则', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          ids: ids
        }
        batchDelete(data)
         .then(res=> {
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.$message.success(res.data.message);
            this.handleSearch(this.tabName);   // 刷新列表
           }
         })
      }).catch(() => {});
    },
    changeState(item) {

    },
    // 指定国家/地区
    handleCountry() {
      this.countryVisible = true;
    },
    handleSelectionToggle(val) {
      this.multipleCountry = val;
    },
    // 确认选择国家
    handleConfirmRegion() {
      this.multipleCountry.forEach(item=> {
        let object = {
          id: item.id,
          nameCn: item.nameCn
        }
        this.dynamicTags.push(object);
      })
      this.countryVisible = false;
    },
    // 选择产品
    handleProduct() {
      let params = {
        current: this.pageGoodsInfo.current,
        keyword: this.pageGoodsInfo.keyword,
        pageSize: this.pageGoodsInfo.pageSize,
      }
      getProductListPage(params)
       .then(res=> {
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           var ids = [];
           this.productListTag.forEach(data=> {
             console.log(data);
             ids.push(data.id)
           })
           this.addGoodsList = res.data.values;
           this.$nextTick(()=> {
             this.addGoodsList.forEach(data=> {
               if(ids.indexOf(data.id)>=0) {
                 this.$refs.multipleTable.toggleRowSelection(row, true);
               }
             })
           })
           this.productTotal = res.data.pageInfo.total;  
         }
       })
      this.productVisible = true;  // 弹框显示
    },
    handleSelectionProduct(val) {
      this.multipleSelectionGoods = val;
      console.log(this.multipleSelectionGoods);
    },
    handleConfirmProduct() {
      this.productListTag = [];    // 数据替换
      this.multipleSelectionGoods.forEach(data=> {
        let object = {
          id: data.id,
          sku: data.commoditySku
        }
        this.productListTag.push(object);
      })
      this.productVisible = false;
    },
    handleSizeProduct(newSize) {
      this.pageGoodsInfo.pageSize = newSize
      this.handleProduct();
    },
    handleCurrentProduct(newCurrent) {
      this.pageGoodsInfo.current = newCurrent;
      this.handleProduct();
    },
    //行拖拽
    // rowDrop() {
    //   const tbody = document.querySelector('.el-table__body-wrapper tbody');
    //   const _this = this
    //   Sortable.create(tbody, {
    //     onEnd({ newIndex, oldIndex }) {
    //       const currRow = _this.tableData.splice(oldIndex, 1)[0]
    //       _this.tableData.splice(newIndex, 0, currRow)
    //     }
    //   })
    // },
    //列拖拽
    // columnDrop() {
    //   const wrapperTr = document.querySelector('.el-table__header-wrapper tr')
    //   this.sortable = Sortable.create(wrapperTr, {
    //     animation: 180,
    //     delay: 0,
    //     onEnd: evt => {
    //       const oldItem = this.dropCol[evt.oldIndex]
    //       this.dropCol.splice(evt.oldIndex, 1)
    //       this.dropCol.splice(evt.newIndex, 0, oldItem)
    //     }
    //   })
    // }
  }
  }
</script>

<style lang="scss" scoped>
.inboundManual{
  width: 100%;
  height: calc(100% - 68px);
  display: flex;
  flex-direction: column;
}
.append {
  .seach {
    .el-select {
      margin-right: 15px;
    }
  }
}
</style>